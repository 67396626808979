import React from "react"
import DesignRectangle from "../images/greener-square.svg"

const SubTitle = ({ bulletPoint, className, children }) => {
  return (
    <h2 className={`font-semibold text-3xl mb-8 ${className}`}>
      {bulletPoint && (
        <img src={DesignRectangle} alt="" className="inline mr-10" />
      )}
      {children}
    </h2>
  )
}

export default SubTitle
