import React from "react"

const ContentBlock = ({ ...props }) => {
  var { className, ...otherProps } = props
  return (
    <div
      className={`relative container md:flex mx-auto py-16 px-10 lg:px-32 ${className}`}
      {...otherProps}
    >
      {otherProps.children}
    </div>
  )
}

export default ContentBlock
